<template>
  <div class="indexPage">
    <div class="formBox" style="width:100%">
      <el-form ref="form" :model="form" label-width="1rem">
        <el-form-item label="头像：" class="headImgBox">

          <el-upload  class="avatar-uploader" :show-file-list="false" action="" :http-request="beforeUploadFile" :auto-upload="true" multiple>
            <img v-if="form.headPortrait!=''" :src="form.headPortrait" class="headImg">
            <img v-else src="../../assets/images/experiment/noHead.png" class="headImg">
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="form.mobilePhone" maxlength="11" disabled>
            <el-button slot="append" type="primary" class="bgffd441" @click="dialogFormVisible = true">{{'\xa0\xa0'}}修改手机号{{'\xa0\xa0'}}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码：">
          <el-input v-model="form.password" type="password" maxlength="11" disabled>
            <el-button slot="append" type="primary" class="bgffd441" @click="passwordVisible = true">修改登录密码</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="身份：">
          <el-input v-if="form.userType == 0" value="学生" disabled></el-input>
          <el-input v-if="form.userType == 1" value="老师" disabled></el-input>
        </el-form-item>
        <el-form-item label="学校：">
          <el-input v-model="form.schoolName" disabled></el-input>
        </el-form-item>
        <el-form-item label="学院：" placeholder="请输入学院">
          <el-input v-model="form.collegeName" disabled></el-input>
        </el-form-item>
        <el-form-item label="学号：" placeholder="请输入学号">
          <el-input v-model="form.studentId" disabled></el-input>
        </el-form-item>
        <el-form-item label="专业：" placeholder="请输入专业">
          <el-input v-model="form.majorName" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:50%;" @click="submitForm('dynamicValidateForm')">保存
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 修改手机号  -->
    <el-dialog title="修改手机号" :visible.sync="dialogFormVisible" width="30%">
      <el-form>
        <el-form-item label-width="0" style="margin:0 0">
          <el-input v-model="formPhone.phoneVal" placeholder="请输入手机号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin:10px 0">
          <div class="inp_flex">
            <el-input v-model="formPhone.imgCodeVal" placeholder="输入图形验证码"></el-input>
            {{'\xa0'}}
            <div class="imgCodeBox">
              <img :src="imgCode" @click="changeImgCode" class="imgCode">
              <!-- <span class="changeImgCodeTxt" @click="changeImgCode" v-if="!isMobile">看不清？换一张</span> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item class="sendsmsBtn">
          <div class="inp_flex">
            <el-input v-model="formPhone.smsCodeVal" placeholder="输入短信验证码"></el-input>
            {{'\xa0'}}
            <el-button type="primary" @click="getSmsCode">{{ sendCodeLabel }}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPhone">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改登录密码" :visible.sync="passwordVisible" width="30%">
      <el-form>
        <el-form-item label-width="0" style="margin:0 0">
          <el-input v-model="form.mobilePhone" disabled placeholder="" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="margin:10px 0">
          <div class="inp_flex">
            <el-input v-model="formPwd.smsCodeVal" placeholder="输入短信验证码"></el-input>
            {{'\xa0'}}
            <el-button type="primary" @click="getPwdCode">{{ sendCodeLabel }}</el-button>
          </div>
        </el-form-item>
        <el-form-item class="sendsmsBtn">
          <div class="inp_flex">
            <el-input v-model="formPwd.password" type="password" placeholder="输入新密码"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="sendsmsBtn">
          <div class="inp_flex">
            <el-input v-model="formPwd.password1"  type="password" placeholder="确认新密码"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button type="primary" @click="onPwdCodeVal">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var timer = require('@/assets/timer.js');
export default {
  name: 'index',
  components: {
  },
  data() {
    return {
      scrollTop: 0,
      token: localStorage.getItem("pc_token"),
      dialogFormVisible: false,
      passwordVisible: false,
      sendCodeLabel: "获取验证码",
      seconds: timer.length,
      imgCode: "",
      ploadImgData: {
        /*file: tempFilePaths[0],*/
        relative: "userReportFile",
        appCode: "userReportFile"
      },
      form: {
        headPortrait: "",
        userName: "",
        mobilePhone: "",
        schoolName: "",
        userType: 0,
        collegeName: "",
        studentId: "",
        chatUserId: "",
        experimentEqCode: "",
        majorName: "",
        email: "",
        password: "",
      },
      formPhone: {
        phoneVal: "",
        imgCodeVal: "",//图形验证码
        smsCodeVal: "",//短信验证码
      },
      formPwd: {
        smsCodeVal: '',
        password: '',
        password1: '',
        isCodeVal: false,
      },
      userInfo: {
        userType: 0,
        userName: "",
      }
    }
  },
  computed: {
  },
  mounted() {
    this.changeImgCode();
    this.getUserInfo(false);
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {

    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    //获取用户信息
    getUserInfo(isFlash) {
      let that = this;
      this.$api.selectMemberUserInfoByToken({
          token: this.token
      }).then(res => {
          if(res.code == 10000){
            let userInfo = res.data;
            that.userInfo = userInfo;
            that.form.headPortrait = userInfo.headPortrait;
            that.form.userName = userInfo.userName;
            that.form.mobilePhone = userInfo.mobilePhone;
            that.form.schoolName = userInfo.schoolName;
            that.form.userType = userInfo.userType;
            that.form.collegeName = userInfo.collegeName;
            that.form.studentId = userInfo.studentId;
            that.form.majorName = userInfo.majorName;
            that.form.experimentEqCode = userInfo.experimentEqCode;
            that.form.email = userInfo.email == null ? "" : userInfo.email;
            that.form.password = userInfo.password;
            that.form.chatUserId = userInfo.chatUserId;
            if(isFlash){
                localStorage.setItem("pc_data", JSON.stringify(res.data));
                this.$store.dispatch('setValue',res.data)
            }
          }
      })
      
    },
    submitForm() {
      console.log('submit!');
      let that = this;
      if (that.form.userName == "") {
        this.$message('请输入姓名！');
        return;
      } else if (that.form.collegeName == "") {
        this.$message('请输入学院！');
        return;
      } else if (that.form.studentId == "") {
        this.$message('请输入学号！');
        return;
      }
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (that.form.email != "") {
        if (!reg.test(that.form.email)) {
          this.$message('请输入正确的邮箱！');
          return;
        }
      }

      this.$api.updateMemberUser({
        token: that.token,
        userNum: that.userInfo.userNum,
        userName: that.form.userName,
        email: that.form.email,
        collegeName: that.form.collegeName,
        studentId: that.form.studentId,
        majorName: that.form.majorName,
        experimentEqCode: that.form.experimentEqCode,
        headPortrait: that.form.headPortrait,
      }).then((res) => {
        that.$message({
          message: '保存成功！',
          type: 'success'
        });
        that.getUserInfo(true);
      })
    },
   
    beforeUploadFile(param) {
      let fd = new FormData();
      fd.append('file', param.file)
      fd.append('sourceName', param.file.name)
      fd.append('relative', "userReportFile")
      fd.append('appCode', "userReportFile");
      const that = this

      const axios = require("axios");
      axios.post(this.$http.httpConfig + this.$http.uploadImgNodeId, fd)
      .then(function (re) {
          if (re.data.code == "10000") {
              that.form.headPortrait = re.data.filePreviewPathFull
          }
      })
      .catch(function (error) {
        console.log(error);
      })
     .then(function () {
                // always executed
      });
    },

    //获取短信验证码
    getSmsCode: function () {
      let that = this;
      if (that.formPhone.phoneVal == "") {
        this.$message({
          message: '请输入您的手机号码！',
          type: 'warning'
        });
        return;
      } else if (that.formPhone.phoneVal.length != 11) {
        this.$message({
          message: '请输入正确的手机号码！',
          type: 'warning'
        });
        return;
      } else if (that.formPhone.imgCodeVal == "") {
        this.$message({
          message: '请输入图形验证码！',
          type: 'warning'
        });
        return;
      }
      if (this.sendCodeBtnDisabled) {
        return;
      }
      // 禁用按钮点击
      this.sendCodeBtnDisabled = true;

      this.$api.doSendSmsV2({
        mobilePhone: that.formPhone.phoneVal,
        terminalName: "pc",
        imgCode: that.formPhone.imgCodeVal,
        t: that.t
      }).then(res => {
        if(res.code == 10000){
         this.$message({
            message: '发送成功',
            type: 'success'
          });
          this.sendCodeLabel = --this.seconds + 's后重发'
          // 启动以1s为步长的倒计时
          that.interval = setInterval(() => {
            timer.countdown(that);
          }, 1000);
        }else{
          that.sendCodeBtnDisabled = false;
          that.changeImgCode()
        }
      }).catch((res) => {
          that.sendCodeBtnDisabled = false;
          that.changeImgCode()
      })
    },
    //获取短信验证码 （修改密码）
    getPwdCode: function () {
      let that = this;
      // 禁用按钮点击
      this.sendCodeBtnDisabled = true;

      this.$api.doSendSms({
        mobilePhone: that.form.mobilePhone,
        terminalName: "pc",
      }).then(res => {
        if(res.code == 10000){
         this.$message({
            message: '发送成功',
            type: 'success'
          });
          this.sendCodeLabel = --this.seconds + 's后重发'
          // 启动以1s为步长的倒计时
          that.interval = setInterval(() => {
            timer.countdown(that);
          }, 1000);
        }else{
          that.sendCodeBtnDisabled = false;
        }
      }).catch((res) => {
         that.sendCodeBtnDisabled = false;
      })

    },

    //切换图形验证码
    changeImgCode: function () {
      this.t = this.createUniqueId(8).join("-")
      let imgCode = this.$http.httpConfig + this.$http.authImage + this.t;
      this.imgCode = imgCode;
    },

    //获取图形验证码
    createUniqueId: function (n) {
      let random = function () { // 生成10-12位不等的字符串
        return Number(Math.random().toString().substr(2)).toString(36); // 转换成十六进制
      };
      let arr = [];

      function createId() {
        var num = random();
        var _bool = false;
        arr.forEach(v => {
          if (v === num) _bool = true;
        });
        if (_bool) {
          createId();
        } else {
          arr.push(num);
        }
      }

      let i = 0;
      while (i < n) {
        createId();
        i++;
      }
      return arr;
    },
    // 修改手机号
    submitPhone() {
      const that = this
      if (that.formPhone.smsCodeVal == "") {
        this.$message({
          message: '请输入您的短信验证码！',
          type: 'warning'
        });
        return;
      }

      this.$api.updateUserPhone({
        token: that.token,
        newPhone: that.formPhone.phoneVal,
        terminalName: "pc",
        newCode: that.formPhone.smsCodeVal,
      }).then(res => {
        if(res.code == 10000){
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.dialogFormVisible = false
          this.getUserInfo(true)
        }
      }).catch((res) => {
       
      })
    },
    submitPassword() {
      const that = this
      if (that.formPwd.password == "") {
        this.$message({
          message: '请输入您的新密码！',
          type: 'warning'
        });
        return;
      }
      if (that.formPwd.password !== that.formPwd.password1) {
        this.$message({
          message: '两次密码不一致！',
          type: 'warning'
        });
        return;
      }
     
      this.$api.doUpdatePwd({
        token: that.token,
        pwd: that.formPwd.password
      }).then(res => {
        if(res.code == 10000){
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.passwordVisible = false
          this.getUserInfo(true)
        }
      }).catch((res) => {
      })
    },
    // 校验短信验证码
    onPwdCodeVal() {
      const that = this
     
      this.$api.checkSmsCode({
         mobilePhone: that.form.mobilePhone,
          terminalName: "pc",
          smsCode: that.formPwd.smsCodeVal
      }).then(res => {
        if(res.code == 10000){
          that.formPwd.isCodeVal = true
          that.submitPassword()
        }
      }).catch((res) => {
      })
    }
  }
}
</script>

<style scoped>
.headImg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* margin-top: 0.1rem; */
}

.formBox {
  width: 100%;
  display: block;
  background: #fff;
  margin: 0.05rem 0rem;
  padding: 0.09rem 25% 0.05rem;
  box-sizing: border-box;
  text-align: center;
}

.el-form-item__content {
  margin-left: 0.8rem;
}

.el-select {
  width: 100%;
}

.headImgBox .el-form-item__label {
  line-height: 0.25rem;
}

@media screen and (max-width: 720px) {
  .headImg {
    float: left;
    width: 0.6rem;
    height: 0.6rem;
  }
}

/deep/ .el-input__inner,
/deep/ .el-button {
  height: 0.25rem;
  line-height: 0.5;
  font-size: 0.09rem;
}

/deep/ .el-form-item__label,
/deep/ .el-form-item__content {
  font-size: 0.09rem;
  line-height: 0.25rem;
}

/deep/ .el-input,
/deep/ .el-divider__text {
  font-size: 0.1rem;
}

/deep/ .el-form-item {
  margin-bottom: 0.12rem;
}
.inp_flex {
  display: flex;
  align-items: center;
}
.imgCodeBox {
  /* position: absolute;
  top: 0;
  right: 0px; */
  display: flex;
}
.imgCode {
  width: 1rem;
  height: 0.3rem;
  display: block;
  margin-top: 0.01rem;
  margin-right: 0.02rem;
}

.bgffd441 {
  background-color: #46d6b9 !important;
  color: #fff !important;
  border-color: #46d6b9 !important;
}
</style>
